$color--error: rgba(255, 108, 108, 0.38);
$color--success: rgba(108, 255, 137, 0.38);
$color--error_border: rgba(255, 0, 0, 0.64);
$global-background: #f2f2f4;
$gray-btn: #f8f8f8;
$gray-btn-subscribe:#787878;
$link-color-1: #74abff;
$gray-50: rgb(172, 172, 172);
$background-light-blue-btn: #cbcbcb;
$default-grey:grey;
$grey-100:#ccc;
$black:black;

// text-size

$width0: 60rem;

$max-width1: 1080px;
$width1: 43rem;

$max-width2: 720px;
$width2: 29rem;

$max-width3: 480px;
$width3: 100%;
$bg-color-3: #e3e6ef;

$link-color-1: rgba(103, 103, 103, 0.56);

$btn-color-1: #ffffff;
$btn-color-2: #f6f6f5;

$accent-blue: #3e7efd;
$accent-background: #d1c0ff;
$input-border-color: #64748b;
