@use './variables.scss' as Variables;

@mixin vertical-line($background, $with-border, $color-border) {
	background: $background;
	min-height: 100%;
	border: $with-border solid $color-border;
}

@mixin flex-space-between {
	display: flex;
	justify-content: space-between;
}

@mixin flex-center-vertical-horizontal {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@mixin flex-direction-column {
	display: flex;
	flex-direction: column;
}

@mixin flex-direction-column-center {
	@include flex-direction-column;
	justify-content: center;
	align-items: center;
}

@mixin flex-align-center {
	display: flex;
	align-items: center;
}

@mixin flex-wrap {
	display: flex;
	flex-wrap: wrap;
}

@mixin wrap-between {
	flex-wrap: wrap;
	width: 100%;

	@media screen and (min-width: Variables.$max-width1 + 1px){
		gap: 1.25rem;
	}

	@media screen and (min-width: Variables.$max-width2 + 1px) and (max-width: Variables.$max-width1) {
		gap: 0.75rem;
	}

	@media screen and (min-width: Variables.$max-width3 + 1px) and (max-width: Variables.$max-width2) {
		gap: 1.5rem;
	}

	@media screen and (max-width: Variables.$max-width3) {
		gap: 0;
	}
}

@mixin flex-space-around {
	display: flex;
	justify-content: space-around;
}

@mixin default-btn {
	background-color: Variables.$btn-color-2;
	min-height: 2rem;
	color: #1c1b1b;
	text-align: center;
	border: none;
	border-radius: 2rem;

	&:hover:not(.default-btn-disabled) {
		background-color: Variables.$btn-color-2;
	}

	&:active:not(.default-btn-disabled) {
		background-color: Variables.$btn-color-1;
		box-shadow: none;
		opacity: 75%;
	}

	&.default-btn-disabled {
		background-color: Variables.$btn-color-1;
		cursor: default;
	}
}

@mixin container {
	display: flex;
	margin: auto;
	flex-direction: row;

	@media screen and (min-width: Variables.$max-width1 + 1px){
		width: Variables.$width0;
	}

	@media screen and (min-width: Variables.$max-width2 + 1px) and (max-width: Variables.$max-width1) {
		width: Variables.$width1;
	}

	@media screen and (min-width: Variables.$max-width3 + 1px) and (max-width: Variables.$max-width2) {
		width: Variables.$width2;
	}

	@media screen and (max-width: Variables.$max-width3) {
		width: Variables.$width3;
	}
}

@mixin search-filters-elem {
	@media screen and (min-width: Variables.$max-width1 + 1px){
		width: 17.5rem;
		height: 3rem;
	}

	@media screen and (min-width: Variables.$max-width2 + 1px) and (max-width: Variables.$max-width1) {
		width: 13rem;
		height: 3rem;
	}

	@media screen and (min-width: Variables.$max-width3 + 1px) and (max-width: Variables.$max-width2) {
		width: 100%;
		height: 3rem;
	}

	@media screen and (max-width: Variables.$max-width3) {
		height: 3rem;
		width: 100%;
	}
}

@mixin event-card-elem {
	border-radius: 8px !important;

	@media screen and (min-width: Variables.$max-width1 + 1px){
		width: 17.5rem !important;
		height: 2.75rem;
	}

	@media screen and (min-width: Variables.$max-width2 + 1px) and (max-width: Variables.$max-width1) {
		width: 12rem !important;
		height: 2.75rem;
	}

	@media screen and (min-width: Variables.$max-width3 + 1px) and (max-width: Variables.$max-width2) {
		width: 100%;
		height: 2.75rem;
	}

	@media screen and (max-width: Variables.$max-width3) {
		width: 100%;
		height: 2.75rem;
	}
}
