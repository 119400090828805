@use '../../variables.scss' as Variables;

.modal {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	min-width: 100vw;
	background: rgba(0, 0, 0, 0.25);
	z-index: 10;
}

.modal-header {
	display: flex;
	justify-content: space-between;

	&__text {
		padding-left: 1rem;
	}
	&__close {
		width: 28px;
		height: 28px;
		cursor: pointer;
	}
}

.wrapper {
	background: #fff;
	border-radius: 10px;
	width: 22rem;
	padding-bottom: 0.3rem;
}

.close {
	cursor: pointer;
	color: Variables.$link-color-1;
	text-decoration: underline;
	font-size: 14px;
	padding-top: 1.3rem;
	padding-right: 0.7rem;
}
